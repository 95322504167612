<template>
  <div class="">
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <div class="d-flex justify-content-between">
          <div class="title3">
            Menú del día
          </div>
          <div>
            <v-icon
              name="calendar-alt"
              class=""
              scale="1"
            />
            <span class="title ml-1">{{ currentDate }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="isBussy2"
      class="mb-2"
    >
      <b-col cols="12">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-50" />
          <strong>Cargando...</strong>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-else
      class="mb-2"
    >
      <b-col
        v-if="isEmpty"
        cols="12"
      >
        <b-card-group deck>
          <b-col
            v-for="(item, index) in menuDay"
            :key="index"
            cols="4"
            class="p-0"
          >
            <menu-day-card :item="item" />
          </b-col>
        </b-card-group>
      </b-col>
      <b-col
        v-else
        cols="12"
      >
        <div class="title text-center">
          No hay menú programado para hoy
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3 d-flex justify-content-center">
      <b-col cols="12">
        <div class="title3">
          Programar menú
        </div>
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-button
          size="sm"
          variant="success"
          @click="modalProgramMenu"
        >
          Programar
        </b-button>
      </b-col>
      <template v-if="isBussy">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-50" />
          <strong>Cargando...</strong>
        </div>
      </template>
      <template v-else>
        <Month
          :events="events"
          @detailOrEdit="detailOrEdit"
        />
      </template>
    </b-row>

    <program-menu-modal
      :day="day"
      :breakfasts="breakfasts"
      :luches="luches"
      :dinners="dinners"
      :dishes="dishes"
      :cold-drinks="coldDrinks"
      :hot-drinks="hotDrinks"
      :dates-breakfast="datesBreakfast"
      :dates-luch="datesLuch"
      :dates-dinner="datesDinner"
      :buttontitle="buttontitle"
      :title="title"
      :is-detail="isDetail"
      @refreshMenus="refreshMenus"
    />
  </div>
</template>

<script>
import {
 BRow, BCol, BSpinner, BButton, BCardGroup,
} from 'bootstrap-vue'
import moment from 'moment'
import Month from '../components/Month'
import ProgramMenuModal from '../components/ProgramMenuModal'
import MenuDayCard from '../components/MenuDayCard'

moment.locale('es')

export default {
	inject: ['disheRepository', 'menuRepository', 'accompanimentRepository'],
	components: {
		Month,
		ProgramMenuModal,
		MenuDayCard,
		BRow,
		BCol,
		BSpinner,
		BButton,
		BCardGroup,
	},
	data() {
		return {
			day: '',
			events: [
				/*         {
          title: 'Programar',
          date: '2021-10-20',
          id: '1',
          backgroundColor: '#7367f0',
          borderColor: '#7367f0',
          textColor: '#fff',
        }, */
			],
			breakfasts: null,
			luches: null,
			dinners: null,
			title: '',
			buttontitle: '',
			dishes: [],
			coldDrinks: [],
			hotDrinks: [],
			menuDay: [],
			isDetail: false,
			currentDate: '',
			isBussy: false,
			isBussy2: false,
			isEmpty: false,
			datesBreakfast: [],
			datesLuch: [],
			datesDinner: [],
		}
	},
	created() {
		const me = this
		me.currentDate = moment().format('YYYY-MM-DD')
		me.loadMenuByDate()
		me.loadMenus()
		me.loadDishes()
		me.loadAccompaniments()
	},
	methods: {
		modalProgramMenu() {
			const me = this
			me.day = null
			me.title = 'Programar Menú'
			me.buttontitle = 'Guardar'
			me.breakfasts = null
			me.luches = null
			me.dinners = null
			me.isDetail = false
			me.$bvModal.show('modal-program-menu')
		},
		refreshMenus(option) {
			const me = this
			me.loadMenus()
			me.loadMenuByDate()
		},
		detailOrEdit(id, data) {
			const me = this
			me.breakfasts = null
			me.luches = null
			me.dinners = null
			data.menus.forEach(el => {
				if (el.type_menu == '1') {
					me.breakfasts = { id: el.id, items: el.items }
				} else if (el.type_menu == '2') {
					me.luches = { id: el.id, items: el.items }
				} else {
					me.dinners = { id: el.id, items: el.items }
				}
			})

			if (id == 1) {
				me.title = 'Actualizar Menú'
				me.buttontitle = 'Actualizar'
				me.isDetail = false
				me.day = data.date
				me.$bvModal.show('modal-program-menu')
			} else if (id == 2) {
				me.isDetail = true
				me.title = 'Detalle Menú'
				me.day = data.date
				me.$bvModal.show('modal-program-menu')
			} else {
				me.deleteMenu(data.menus)
			}
		},
		async loadDishes() {
			const me = this
			try {
				const res = await me.disheRepository.getAll()
				me.dishes = res.data
			} catch (error) {
				// console.log(error)
			}
		},
		async loadAccompaniments() {
			const me = this
			try {
				const res = await me.accompanimentRepository.getAll()
				res.data.map(el =>
					el.type_accompaniment == 1
						? me.coldDrinks.push(el)
						: me.hotDrinks.push(el),
				)
			} catch (error) {
				// console.log(error)
			}
		},
		async loadMenus() {
			const me = this
			try {
				me.isBussy = true
				const menus = []
				me.events = []
				const datesBreakfast = []
				const datesLuch = []
				const datesDinner = []

				const res = await me.menuRepository.getAll()
				const { data } = res
				data.forEach(el => {
					const menu = menus.find(x => x.date == el.date)

					if (menu) {
						menu.menus.push({
							id: el.id,
							type_menu: el.type_menu,
							items: el.items,
						})
					} else {
						menus.push({
							date: el.date,
							menus: [
								{
									id: el.id,
									type_menu: el.type_menu,
									items: el.items,
								},
							],
						})
					}
				})

				menus.forEach(el => {
					el.menus.forEach(x => {
						if (x.type_menu == '1') {
							datesBreakfast.push(el.date)
						} else if (x.type_menu == '2') {
							datesLuch.push(el.date)
						} else {
							datesDinner.push(el.date)
						}
					})
				})

				me.loadDates(datesBreakfast, datesLuch, datesDinner)
				menus.forEach(el => {
					me.events.push(
						{
							title: 'Editar',
							date: el.date,
							backgroundColor: '#7367f0',
							borderColor: '#7367f0',
							textColor: '#fff',
							extendedProps: el,
							id: 1,
						},
						{
							title: 'Ver detalle',
							date: el.date,
							backgroundColor: '#28c76f',
							borderColor: '#28c76f',
							textColor: '#fff',
							extendedProps: el,
							id: 2,
						},
						{
							title: 'Eliminar',
							date: el.date,
							backgroundColor: '#ea5455',
							borderColor: '#ea5455',
							textColor: '#fff',
							extendedProps: el,
							id: 3,
						},
					)
				})
			} catch (error) {
				// console.log(error)
			} finally {
				me.isBussy = false
			}
		},
		async loadMenuByDate() {
			const me = this
			try {
				me.isBussy2 = true
				me.menuDay = []
				const res = await me.menuRepository.getAllMenuByDate(me.currentDate)
				me.isEmpty = true
				const { data } = res
				data.sort((a, b) => {
					if (a.type_menu > b.type_menu) {
						return 1
					}
					if (a.type_menu < b.type_menu) {
						return -1
					}
					return 0
				})
				data.forEach(el => {
					if (el.type_menu == '1') {
						me.addItemMenuDay(el, 'Desayuno')
					} else if (el.type_menu == '2') {
						me.addItemMenuDay(el, 'Almuerzo')
					} else {
						me.addItemMenuDay(el, 'Cena')
					}
				})
			} catch (error) {
				me.isEmpty = false
				// console.log(error)
			} finally {
				me.isBussy2 = false
			}
		},
		addItemMenuDay(data, type) {
			const me = this
			let dishes = []
			let hotDrinks = []
			let coldDrinks = []
			if (data) {
				data.items.forEach(x => {
					if (x.accompaniment == '0') {
						dishes.push(x)
					} else if (
						x.type_accompaniment !== null &&
						x.type_accompaniment == '1'
					) {
						coldDrinks.push(x)
					} else {
						hotDrinks.push(x)
					}
				})
			} else {
				dishes = null
				hotDrinks = null
				coldDrinks = null
			}

			const item = {
				title: type,
				dishes,
				hotDrinks,
				coldDrinks,
			}

			me.menuDay.push(item)
		},
		loadDates(datesBreakfast, datesLuch, datesDinner) {
			const me = this
			me.datesBreakfast = []
			me.datesLuch = []
			me.datesDinner = []

			if (datesBreakfast.length > 0) {
				me.datesBreakfast.push({
					start: new Date(`${datesBreakfast[0]} 00:00:00`),
					end: new Date(
						`${datesBreakfast[datesBreakfast.length - 1]} 00:00:00`,
					),
				})
			} else {
				me.datesBreakfast.push({
					start: null,
					end: me.loadYesterday(),
				})
			}

			if (datesLuch.length > 0) {
				me.datesLuch.push({
					start: new Date(`${datesLuch[0]} 00:00:00`),
					end: new Date(
						`${datesLuch[datesLuch.length - 1]} 00:00:00`,
					),
				})
			} else {
				me.datesLuch.push({
					start: null,
					end: me.loadYesterday(),
				})
			}

			if (datesDinner.lenght > 0) {
				me.datesDinner.push({
					start: new Date(`${datesDinner[0]} 00:00:00`),
					end: new Date(
						`${datesDinner[datesDinner.length - 1]} 00:00:00`,
					),
				})
			} else {
				me.datesDinner.push({
					start: null,
					end: me.loadYesterday(),
				})
			}
		},
    loadYesterday() {
      const today = new Date()
      const dayToMilliseconds = 24 * 60 * 60 * 1000
      const yesterday = new Date(today.getTime() - dayToMilliseconds)
      return yesterday
    },
		async deleteMenu(menus) {
			const me = this
			this.$bvModal
				.msgBoxConfirm('¿Está seguro que desea eliminar el menú?', {
					title: 'Eliminar menú',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Eliminar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cancelar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
						try {
							menus.forEach(async el => {
								await me.menuRepository.delete(el.id)
							})
							await me.loadMenus()
						} catch (e) {
							console.error(e)
							me.$bvToast.toast('Error', {
								title: 'Ocurrió un error al eliminar el registro',
								toaster: 'b-toaster-bottom-right',
								variant: 'danger',
								solid: true,
								autoHideDelay: 3000,
							})
						}
					}
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 14px;
	color: #636363;
	font-weight: 700;
}

.title2 {
	font-size: 18px;
	color: #636363;
	font-weight: 700;
	margin-bottom: 5px;
}

.title3 {
	font-size: 28px;
	color: #636363;
	font-weight: 700;
}
</style>
