import { render, staticRenderFns } from "./MenuDayCard.vue?vue&type=template&id=ee4e8dd2&scoped=true&"
import script from "./MenuDayCard.vue?vue&type=script&lang=js&"
export * from "./MenuDayCard.vue?vue&type=script&lang=js&"
import style0 from "./MenuDayCard.vue?vue&type=style&index=0&id=ee4e8dd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee4e8dd2",
  null
  
)

export default component.exports