<template>
  <b-card
    no-body
    class="card__ h-100"
  >
    <template #header>
      <div class="title2">
        {{ item.title }}
      </div>
    </template>
    <div class="padding__">
      <b-row class="margin_bottom">
        <b-col cols="12">
          <div class="title text-warning">
            Platos principales
          </div>
        </b-col>
        <template v-if="item.dishes.length > 0">
          <b-col
            v-for="(menu) in item.dishes"
            :key="menu.id"
            cols="4"
          >
            <div
              v-b-tooltip.hover.top="menu.name"
              class="item_menu margin_bottom"
            >
              <feather-icon icon="CheckCircleIcon" />
              {{ menu.name }}
            </div>
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12">
            <div class="subtitle">
              Sin registros
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row class="margin_bottom">
        <b-col cols="12">
          <div class="title text-danger">
            Bebidas calientes
          </div>
        </b-col>
        <template v-if="item.hotDrinks.length > 0">
          <b-col
            v-for="(menu) in item.hotDrinks"
            :key="menu.id"
            cols="4"
          >
            <div
              v-b-tooltip.hover.top="menu.name"
              class="item_menu margin_bottom"
            >
              <feather-icon icon="CheckCircleIcon" />
              {{ menu.name }}
            </div>
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12">
            <div class="subtitle">
              Sin registros
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row class="margin_bottom">
        <b-col cols="12">
          <div class="title text-success">
            Bebidas frías
          </div>
        </b-col>
        <template v-if="item.coldDrinks.length > 0">
          <b-col
            v-for="(menu) in item.coldDrinks"
            :key="menu.id"
            cols="4"
          >
            <div
              v-b-tooltip.hover.top="menu.name"
              class="item_menu margin_bottom"
            >
              <feather-icon icon="CheckCircleIcon" />
              {{ menu.name }}
            </div>
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12">
            <div class="subtitle">
              Sin registros
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
		BRow,
		BCol,
		BCard,
	},
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(100, 100, 100, 0.3),
      0 4px 15px 0 rgba(77, 76, 76, 0.15) !important;
    /* height: 280px !important; */
  }

  .card-header {
    background-color: rgb(236, 234, 234);
    padding: 8px;
  }

  .img__ {
    height: 55px;
    width: 80px;
  }

  .padding__ {
    padding: 8px;
  }

  .title {
    font-size: 14px;
    color: #636363;
    font-weight: 700;
  }

  .subtitle {
    font-size: 14px;
    color: #636363;
  }

  .title2 {
    font-size: 18px;
    color: #636363;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .margin_bottom {
    margin-bottom: 5px;
  }

  .item_menu {
    width: 85px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
